import React, {lazy, Suspense} from 'react'
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from 'react-router-dom'
import Loading from './components/atoms/Loading';

const getLazyComponent = (containerName: string) => {
	switch (containerName) {
		case 'HomePage':
			return import(/* webpackChunkName: "home" */ './pages/Home');
		case 'WebPresencePage':
			return import(/* webpackChunkName: "web-presence" */ './pages/WebPresence');
		case 'ProjectsPage':
			return import(/* webpackChunkName: "projects" */ './pages/Projects');
		default:
			return Promise.reject('No container with specified name found!');
	}
}

const App: React.FC = () => {
	const HomePage = lazy(() => getLazyComponent('HomePage'))
	const WebPresencePage = lazy(() => getLazyComponent('WebPresencePage'))
	const ProjectsPage = lazy(() => getLazyComponent('ProjectsPage'))
	const router = createBrowserRouter(
		createRoutesFromElements(
			<Route path="/" element={<Suspense fallback={<Loading />}><HomePage /></Suspense>}>
				<Route path="/web-presence" element={<Suspense fallback={<Loading />}><WebPresencePage /></Suspense>} />
				<Route path="/projects" element={<Suspense fallback={<Loading />}><ProjectsPage /></Suspense>} />
			</Route>
		)
	);
	return (
		<RouterProvider router={router} />
	)
}

export default App
